import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

// includes
import CommonStudentView from "includes/studentView";
import ApplicationProcess from "includes/applicationProcess";
import { api, common, types } from "helpers";
import StatusBar from "elements/statusBar";
import FieldSelect from "elements/fieldSelect";

function View({ socket }) {
  const { id } = useParams();
  const navigate = useNavigate();
  // state
  const [status, setStatus] = useState("process");
  const [result, setResult] = useState(null);
  const [key, setKey] = useState("status");
  const [loading, setLoading] = useState(null);
  const [salesPerson, setSalesPerson] = useState({});
  const [permission] = useState(common.getUserPermission());

  //socket
  useEffect(() => {
    socket.on(`${types?.socket?.APPLICATION_TIMELINE}`, (data) => {
      console.log("data", data);
      if (data?.appId === id) {
        setStatus("process");
        getInfo();
      }
    });
  }, [socket]);

  // effect
  useEffect(() => {
    getInfo();
  }, []);

  // api
  const getInfo = () => {
    let data = {
      url: "application",
      method: "GET",
      query: `/${id}`,
    };

    api.call(data, (res) => {
      if (res.status === 200) {
        setResult(res.data);
        // sales person
        const { salesPerson } = res.data;

        if (salesPerson) {
          setSalesPerson({
            label: salesPerson?.salesPersonEmail,
            value: salesPerson?.salesPersonId,
          });
        }
        setStatus("success");
      } else {
        setStatus("error");
      }
    });
  };

  const appUpdate = (field, value) => {
    setLoading("status");
    let data = {
      url: "application_update",
      method: "PUT",
      query: `/${id}`,
      body: {
        [field]: value,
      },
    };
    api.call(data, (res) => {
      if (res.status === 200) {
        getInfo();
        common.notify("S", "Record has been updated");
      } else {
        common.notify("E", "Unable to update data please try again");
      }
      setLoading(null);
    });
  };

  return (
    <Fragment>
      <div className="title-wrap d-flex align-items-center">
        <img
          src={common.loadImg("back-icn.svg")}
          className="ed-cp"
          alt=""
          onClick={() => navigate(-1)}
        />
        <h1 className="page-title ps-4">Application View</h1>
      </div>
      <div className="content-wrap">
        <div className="inner-content-wrap">
          {status !== "success" ? (
            <StatusBar status={status} />
          ) : (
            <Fragment>
              <Tabs
                id="controlled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="ed-tabs app-status-tab mb-3"
              >
                <Tab eventKey="status" title="Application Info">
                  <div className="et-vc-info et-view-border-bottom">
                    <ul className="et-vc-list row">
                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Application Id</p>
                          <p className="et-field-value">
                            {common.readable("string", result.refId)}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Submited At</p>
                          <p className="et-field-value">
                            {common.readable("dateTime", result.createdAt)}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Current Status</p>
                          <p className="et-field-value">
                            <span
                              className={`alert et-status et_status_${result.currentStatus}`}
                            >
                              {types.applicationStatus[result.currentStatus]}
                            </span>
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Course Name</p>
                          <p className="et-field-value">
                            {result.course?.name}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">University Name</p>
                          <p className="et-field-value">
                            {result.course?.university?.name}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Intake</p>
                          <p className="et-field-value">
                            {common.getMonthList()[result.month - 1].label}{" "}
                            {result.year}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Agent Details</p>
                          <p className="et-field-value">
                            {result?.profile?.agentId?.email || "-"}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Student Details</p>
                          <p className="et-field-value">
                            {result.profile?.firstName}{" "}
                            {result.profile?.lastName}
                          </p>
                        </div>
                      </li>

                      <li
                        className={`col-md-4 et-view-border-bottom et-view-border-right`}
                      >
                        <div className="et-list-inner">
                          <p className="et-field-name">Responsible Person</p>
                          {/* <p className="et-field-value">
                            {result.salesPersonId?.email || "-"}
                          </p> */}
                          <p className="et-field-value">
                            <FieldSelect
                              api="users"
                              query="?offset=0&limit=1000"
                              get="email"
                              set="_id"
                              value={salesPerson}
                              render={"yes"}
                              clearable={false}
                              finder={false}
                              multi={false}
                              onSelect={(data) => {
                                setSalesPerson(data);
                                appUpdate("salesPersonId", data.value);
                              }}
                              disabled={
                                permission.includes("apr") ? false : true
                              }
                            />
                          </p>
                        </div>
                      </li>

                      {result?.subAgentId && (
                        <li
                          className={`col-md-4 et-view-border-bottom et-view-border-right`}
                        >
                          <div className="et-list-inner">
                            <p className="et-field-name">
                              Application Verified
                            </p>
                            <p className="et-field-value">
                              {result?.isVerified ? "Yes" : "No"}
                            </p>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                </Tab>
                <Tab eventKey="info" title="Profile Info">
                  <CommonStudentView
                    id={id}
                    formType="application"
                    formModule="all"
                  />
                </Tab>
                <Tab eventKey="view" title="Process">
                  <ApplicationProcess id={id} result={result} />
                </Tab>
              </Tabs>
            </Fragment>
          )}
        </div>
      </div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading !== null}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Fragment>
  );
}
export default View;
