import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { io } from "socket.io-client";
import { config } from "./helpers/config";
// includes
// layouts
import InnerLayout from "layouts/innerLayout";
// auth
import SignIn from "pages/auth/signin";
import ForgotPassword from "pages/auth/forgotPassword";
import OtpVerification from "pages/auth/otpVerification";
import ResetPassword from "pages/auth/resetPassword";
// home
import Home from "pages/home/home";
// agent
import AgentForm from "pages/agent/form";
import AgentList from "pages/agent/list";
import AgentView from "pages/agent/view";
// student
import StudentForm from "pages/student/form";
import StudentList from "pages/student/list";
import StudentView from "pages/student/view";
import StudentEdit from "pages/student/edit";
// application
import ApplicationList from "pages/application/list";
import ApplicationView from "pages/application/view";
// university
import UniversityForm from "pages/university/form";
import UniversityList from "pages/university/list";
import UniversityView from "pages/university/view";
// course
import CourseForm from "pages/course/form";
import CourseList from "pages/course/list";
import CourseView from "pages/course/view";
// subject
import SubjectList from "pages/subject/list";
// users
import UsersForm from "pages/users/form";
import { default as UsersList, default as UsersView } from "pages/users/list";
// users
import PromoForm from "pages/promo/form";
import PromoList from "pages/promo/list";
// setup
import SetupForm from "pages/setup/form";
// profile
import Profile from "pages/profile/profile";
//Enquiry
import Enquiry from "pages/enquiry/list";
import EnquiryView from "pages/enquiry/view";
// member
import MemberList from "pages/members/list";
import MemberView from "pages/members/view";
// cashback
import CashbackList from "pages/cashback/list";
import CashbackView from "pages/cashback/view";
// cashback
import CashbackRequestList from "pages/cashbackRequest/list";
import CashbackRequestView from "pages/cashbackRequest/view";
//Blog
import Category from "pages/blog/category/list";
import CategoryForm from "pages/blog/category/form";
import CategoryView from "pages/blog/category/view";
import Blog from "pages/blog/list";
import BlogForm from "pages/blog/form";
import BlogView from "pages/blog/view";
//Career
import JobCategory from "pages/career/category/list";
import JobCategoryForm from "pages/career/category/form";
import JobCategoryView from "pages/career/category/view";
import JobSkill from "pages/career/skill/list";
import JobSkillForm from "pages/career/skill/form";
import JobSkillView from "pages/career/skill/view";
import Career from "pages/career/list";
import CareerForm from "pages/career/form";
import CareerView from "pages/career/view";
import PermissionList from "pages/permission/list";
import PermissionForm from "pages/permission/form";

const socket = io(config?.api?.socket_url);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<SignIn />} />
        <Route path="/password/forgot" element={<ForgotPassword />} />
        <Route path="/password/reset" element={<ResetPassword />} />
        <Route path="/otp/verify" element={<OtpVerification />} />
        <Route path="/" element={<InnerLayout />}>
          {/* home */}
          <Route path="/home" element={<Home />} />
          {/* agent */}
          <Route path="/agent/add" element={<AgentForm />} />
          <Route path="/agent" element={<AgentList />} />
          <Route path="/agent/view/:id" element={<AgentView />} />
          {/* student */}
          <Route path="/student/add" element={<StudentForm />} />
          <Route path="/student" element={<StudentList />} />
          <Route path="/student/view/:id" element={<StudentView />} />
          <Route
            path="student/edit/:id/:type/:from"
            element={<StudentEdit />}
          />
          {/* application */}
          <Route path="/application" element={<ApplicationList />} />
          <Route
            path="/application/view/:id"
            element={<ApplicationView socket={socket} />}
          />
          {/* university */}
          <Route path="/university" element={<UniversityList />} />
          <Route path="/university/add" element={<UniversityForm />} />
          <Route path="/university/edit/:id" element={<UniversityForm />} />
          <Route path="/university/view/:id" element={<UniversityView />} />
          {/* course */}
          <Route path="/course" element={<CourseList />} />
          <Route path="/course/add" element={<CourseForm />} />
          <Route path="/course/edit/:id" element={<CourseForm />} />
          <Route path="/course/view/:id" element={<CourseView />} />
          {/* subject */}
          <Route path="/subject" element={<SubjectList />} />
          {/* promo code */}
          <Route path="/promo" element={<PromoList />} />
          <Route path="/promo/add" element={<PromoForm />} />
          <Route path="/promo/edit/:id" element={<PromoForm />} />
          {/* users */}
          <Route path="/users" element={<UsersList />} />
          <Route path="/users/add" element={<UsersForm />} />
          <Route path="/users/edit/:id" element={<UsersForm />} />
          <Route path="/users/view/:id" element={<UsersView />} />
          {/* setup */}
          <Route path="/setup" element={<SetupForm />} />
          {/* profile */}
          <Route path="/profile" element={<Profile />} />
          {/* Enquiry */}
          <Route path="/enquiry" element={<Enquiry />} />
          <Route path="/enquiry/view/:id" element={<EnquiryView />} />
          {/* subAgent */}
          <Route path="/member" element={<MemberList />} />
          <Route path="/member/:id" element={<MemberList />} />
          <Route path="/member/view/:id" element={<MemberView />} />
          {/* cashback */}
          <Route path="/cashback" element={<CashbackList />} />
          <Route path="/cashback/edit/:id" element={<CourseForm />} />
          <Route path="/cashback/view/:id" element={<CashbackView />} />
          {/* cashback request */}
          <Route path="/cashbackrequest" element={<CashbackRequestList />} />
          <Route path="/cashbackrequest/edit/:id" element={<CourseForm />} />
          <Route
            path="/cashbackrequest/view/:id"
            element={<CashbackRequestView />}
          />
          {/* Blog */}
          <Route path="/category" element={<Category />} />
          <Route path="/category/add" element={<CategoryForm />} />
          <Route path="/category/edit/:id" element={<CategoryForm />} />
          <Route path="/category/view/:id" element={<CategoryView />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/add" element={<BlogForm />} />
          <Route path="/blog/edit/:id" element={<BlogForm />} />
          <Route path="/blog/view/:id" element={<BlogView />} />
          {/* Career */}
          <Route path="/jobcategory" element={<JobCategory />} />
          <Route path="/jobcategory/add" element={<JobCategoryForm />} />
          <Route path="/jobcategory/edit/:id" element={<JobCategoryForm />} />
          <Route path="/jobcategory/view/:id" element={<JobCategoryView />} />
          <Route path="/jobskill" element={<JobSkill />} />
          <Route path="/jobskill/add" element={<JobSkillForm />} />
          <Route path="/jobskill/edit/:id" element={<JobSkillForm />} />
          <Route path="/jobskill/view/:id" element={<JobSkillView />} />
          <Route path="/career" element={<Career />} />
          <Route path="/career/add" element={<CareerForm />} />
          <Route path="/career/edit/:id" element={<CareerForm />} />
          <Route path="/career/view/:id" element={<CareerView />} />
          <Route path="/permissions" element={<PermissionList />} />
          <Route path="/permissions/add" element={<PermissionForm />} />
          <Route path="/permissions/edit/:id" element={<PermissionForm />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
